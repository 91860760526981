var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "rpagination ma-0",
    attrs: {
      "justify": _vm.justify
    }
  }, [_vm._l(_vm.actionItems, function (actionItem) {
    return _c('div', {
      key: "action-item-".concat(actionItem.id),
      staticClass: "pagination-action-item-container",
      on: {
        "click": function click($event) {
          return _vm.onClickActionItem(actionItem);
        }
      }
    }, [_c('div', {
      staticClass: "pagination-action-item-text"
    }, [_vm._v(" " + _vm._s(_vm.$t(actionItem.name)) + " ")]), _c('div', {
      staticClass: "pagination-action-item-divider"
    })]);
  }), _c('r-chip', {
    staticClass: "mr-2 rpagination--previous",
    attrs: {
      "icon": "chevron_left",
      "disabled": _vm.page === 1,
      "outlined": "",
      "circle": ""
    },
    on: {
      "click": _vm.onClickPreviousPage
    }
  }), _vm.showFirstPageLink ? _c('r-chip', {
    staticClass: "mr-2 rpagination--firstpage",
    attrs: {
      "label": "1",
      "outlined": "",
      "circle": ""
    },
    on: {
      "click": function click($event) {
        return _vm.onClickPage(1);
      }
    }
  }) : _vm._e(), _vm.showFirstPageSeparator ? _c('span', {
    staticClass: "mr-2 rpagination--separator rpagination--separator--previous"
  }, [_vm._v("...")]) : _vm._e(), _vm._l(_vm.pagesBefore, function (pageNum) {
    return _c('r-chip', {
      key: "rpagination--chip--page--".concat(pageNum),
      staticClass: "mr-2 rpagination--page",
      class: {
        'rpagination--active': _vm.page === pageNum
      },
      attrs: {
        "label": "".concat(pageNum),
        "active": _vm.page === pageNum,
        "outlined": _vm.page !== pageNum,
        "circle": ""
      },
      on: {
        "click": function click($event) {
          return _vm.onClickPage(pageNum);
        }
      }
    });
  }), _vm.showEndPageSeparator ? _c('span', {
    staticClass: "mr-2 rpagination--separator rpagination--separator--next"
  }, [_vm._v("...")]) : _vm._e(), _vm.showEndPageLink ? _c('r-chip', {
    staticClass: "mr-2 rpagination--lastpage",
    attrs: {
      "label": "".concat(_vm.totalPages),
      "outlined": "",
      "circle": ""
    },
    on: {
      "click": function click($event) {
        return _vm.onClickPage(_vm.totalPages);
      }
    }
  }) : _vm._e(), _c('r-chip', {
    staticClass: "mr-2 rpagination--next",
    attrs: {
      "icon": "chevron_right",
      "disabled": _vm.page === _vm.totalPages,
      "outlined": "",
      "circle": ""
    },
    on: {
      "click": _vm.onClickNextPage
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }