var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "rtable",
    staticClass: "rtable"
  }, [_vm.numBatchSelectedRows ? _c('div', {
    staticClass: "rtable--batchselect",
    style: {
      height: "".concat(_vm.batchSelectRowHeight, "px")
    }
  }, [!_vm.selectAllPages ? _c('span', [_vm._v(" " + _vm._s(_vm.numBatchSelectedRows) + " row" + _vm._s(_vm.numBatchSelectedRows !== 1 ? 's' : '') + " selected. "), _vm.wholePageSelected && _vm.numBatchSelectedRows !== _vm.totalRows ? _c('a', {
    staticClass: "rtable--batchselect--allpages",
    on: {
      "click": function click($event) {
        _vm.selectAllPages = true;
      }
    }
  }, [_vm._v(" Select all " + _vm._s(_vm.totalRows) + " rows ")]) : _vm._e()]) : _c('span', [_vm._v(" All " + _vm._s(_vm.totalRows) + " rows selected. "), _c('a', {
    on: {
      "click": _vm.onClickBatchSelectThisPageOnly
    }
  }, [_vm._v(" Select this page only ")])])]) : _vm._e(), _vm.initialLoading ? _c('div', {
    staticClass: "rtable--headerbg"
  }) : _vm._e(), _vm.noData ? _c('no-listing-results') : _c('div', {
    staticClass: "rtable--data--container",
    staticStyle: {
      "position": "relative"
    }
  }, [_c('table', {
    ref: "rtable--table",
    staticClass: "rtable--table"
  }, [_c('thead', {
    ref: "rtable--header",
    staticClass: "rtable--header"
  }, [_c('tr', {
    staticClass: "rtable--row d-flex align-center"
  }, _vm._l(_vm.visibleColumns, function (column, i) {
    return _c('th', {
      key: "".concat(column.key, "-").concat(column.sorted),
      staticClass: "rtable--cell",
      class: {
        'd-flex align-center': _vm.hasHiddenColumns && i === _vm.visibleColumns.length - 1
      },
      style: _vm.headerCellStyle(column, i)
    }, [i === 0 && _vm.isSelectable ? [_c('v-checkbox', {
      staticClass: "rtable--batchselectall",
      attrs: {
        "dense": ""
      },
      on: {
        "click": _vm.onClickBatchSelectAll
      },
      model: {
        value: _vm.batchSelectAll,
        callback: function callback($$v) {
          _vm.batchSelectAll = $$v;
        },
        expression: "batchSelectAll"
      }
    })] : _c('div', {
      staticClass: "d-flex align-center"
    }, [column.sortable || column.hideable ? _c('a', {
      staticClass: "rtable--popuptrigger",
      on: {
        "click": function click($event) {
          return _vm.onClickChangeColumn($event, column, i);
        }
      }
    }, [_vm._v(_vm._s(column.label))]) : _c('span', {
      staticClass: "rtable--unselectable"
    }, [_vm._v(_vm._s(column.label))]), column.sorted === 'desc' ? _c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v(" arrow_drop_down ")]) : column.sorted === 'asc' ? _c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v(" arrow_drop_up ")]) : _vm._e()], 1)], 2);
  }), 0)]), _c('tbody', {
    ref: "rtable--body",
    staticClass: "rtable--body"
  }, _vm._l(_vm.rows, function (row, i) {
    return _c('tr', {
      key: "renderedrow-".concat(i),
      ref: "rtable--renderedrow",
      refInFor: true,
      staticClass: "rtable--row d-flex align-center"
    }, _vm._l(_vm.visibleColumns, function (column, j) {
      return _c('td', {
        key: "".concat(column.key, "-").concat(row._id),
        staticClass: "rtable--cell",
        style: _vm.cellStyle(column, j)
      }, [j === 0 && _vm.isSelectable ? [_c('v-checkbox', {
        staticClass: "rtable--batchselect",
        attrs: {
          "dense": ""
        },
        model: {
          value: _vm.batchSelectedRows[row._id],
          callback: function callback($$v) {
            _vm.$set(_vm.batchSelectedRows, row._id, $$v);
          },
          expression: "batchSelectedRows[row._id]"
        }
      })] : _vm._t("item.".concat(column.key), function () {
        return [!Array.isArray(row[column.key]) || row[column.key].length > 0 ? _c('span', [_vm._v(" " + _vm._s(row[column.key]) + " ")]) : _vm._e()];
      }, {
        "item": row
      })], 2);
    }), 0);
  }), 0)]), _vm.hasHiddenColumns ? [_c('v-btn', {
    ref: "rtable--add-column",
    staticClass: "rtable--popuptrigger rtable--add-column",
    style: _vm.plusButtonStyle,
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.onClickShowAddColumnPopup
    }
  }, [_c('v-icon', [_vm._v("add")])], 1)] : _vm._e()], 2), _vm.rows.length ? _c('r-table-pagination', {
    key: "pagination-size-".concat(_vm.totalRows),
    ref: "rtable--footer",
    attrs: {
      "page-size": _vm.pageSize,
      "current-page": _vm.page,
      "total-rows": _vm.totalRows,
      "show-all": _vm.totalRows === _vm.rows.length,
      "action-items": _vm.actionItems
    },
    on: {
      "click:page-size-all": function clickPageSizeAll($event) {
        return _vm.$emit('change:page-size-all');
      },
      "click:page-size": function clickPageSize(data) {
        return _vm.$emit('change:page-size', data);
      },
      "click:page": function clickPage(data) {
        return _vm.$emit('change:page', data);
      },
      "open:pagination-action-item": _vm.onClickActionItem
    }
  }) : _vm._e(), _vm.showAddColumn ? _c('r-table-popup', {
    style: _vm.addColumnPopupStyle,
    attrs: {
      "items": _vm.hiddenColumns.map(function (c) {
        return Object.assign({}, c, {
          icon: 'add'
        });
      })
    },
    on: {
      "click:item": _vm.onClickAddColumn
    }
  }) : _vm._e(), _vm.showChangeColumn ? _c('r-table-popup', {
    style: _vm.changeColumnPopupStyle,
    attrs: {
      "items": _vm.changeColumnItems
    },
    on: {
      "click:item": _vm.onClickChangeColumnItem
    }
  }) : _vm._e(), _vm.loading ? _c('div', {
    ref: "rtable--loading",
    staticClass: "rtable--loading"
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": ""
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }