var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rswitch d-flex justify-start"
  }, [_vm.label ? _c('label', {
    staticClass: "pr-3"
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e(), _c('v-switch', {
    attrs: {
      "disabled": _vm.disabled,
      "inset": ""
    },
    on: {
      "change": _vm.onChange
    },
    model: {
      value: _vm.newValue,
      callback: function callback($$v) {
        _vm.newValue = $$v;
      },
      expression: "newValue"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }