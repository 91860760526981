var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "newresultsfilterchips d-flex"
  }, _vm._l(_vm.renderedFilters, function (filter) {
    return _c('r-chip', {
      key: "newresultsfilterchips-".concat(filter.key),
      staticClass: "newresultsfilterchips--chip mb-4 mr-2",
      attrs: {
        "label": filter.label,
        "disabled": _vm.loading,
        "removable": true
      },
      on: {
        "click": function click($event) {
          return _vm.onClickRemove([filter.key]);
        }
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }