var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    staticClass: "rmodal",
    attrs: {
      "hide-overlay": "",
      "persistent": "",
      "no-click-animation": "",
      "width": "0"
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-overlay', {
    attrs: {
      "opacity": "0.5",
      "color": "white",
      "dark": false
    }
  }, [_c('v-card', {
    staticStyle: {
      "background-color": "white",
      "box-shadow": "1px 1px 5px -2px"
    },
    attrs: {
      "min-width": "650px"
    }
  }, [_c('v-card-title', {
    staticClass: "px-6 py-4",
    staticStyle: {
      "justify-content": "center"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.title))])]), _vm.subTitle ? _c('span', [_vm._v(_vm._s(_vm.subTitle))]) : _vm._e(), _vm.errorMessage ? _c('div', {
    staticClass: "ma-4"
  }, [_c('v-alert', {
    staticClass: "mb-0",
    attrs: {
      "type": "error",
      "dense": ""
    }
  }, [_c('small', [_vm._v(_vm._s(_vm.errorMessage))])])], 1) : _vm._e(), _vm.fetchingInitial ? _c('v-card-text', {
    staticClass: "pt-4",
    style: _vm.contentStyle,
    attrs: {
      "data-testid": "initial-loading"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  })], 1), _c('v-row', {
    staticClass: "mt-4",
    attrs: {
      "justify": "center"
    }
  }, [_c('p', [_vm._v("Fetching")])])], 1) : _c('v-card-text', {
    staticClass: "pt-4 content-container",
    style: _vm.contentStyle
  }, [_vm._t("content")], 2), _c('v-card-actions', [!!this.$slots.customActions ? _c('div', [_vm._t("customActions")], 2) : _c('div', {
    staticClass: "actions-container"
  }, [!_vm.actionCompleted && _vm.closeActionLabel ? _c('r-button', {
    staticClass: "close-btn",
    attrs: {
      "data-testid": "cancel",
      "outlined": "",
      "label": _vm.closeActionLabel,
      "disabled": _vm.fetching
    },
    on: {
      "click": _vm.onClickCancel
    }
  }) : _vm._e(), !_vm.actionCompleted && _vm.alternateActionLabel ? _c('r-button', {
    staticClass: "submit-alternate-btn",
    attrs: {
      "data-testid": "submit",
      "label": _vm.alternateActionLabel,
      "loading": _vm.fetching,
      "disabled": _vm.alternateActionDisabled
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('submit:alternate');
      }
    }
  }) : _vm._e(), !_vm.actionCompleted && _vm.actionLabel ? _c('r-button', {
    staticClass: "submit-btn",
    attrs: {
      "data-testid": "submit",
      "label": _vm.actionLabel,
      "disabled": _vm.actionDisabled,
      "loading": _vm.fetching
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('submit');
      }
    }
  }) : _vm._e()], 1)])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }