var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "searchresults"
  }, [_c('v-form', {
    ref: "form",
    staticClass: "searchresults--form",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onClickSave.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-row', {
    staticClass: "searchresults-header mb-10 mt-4"
  }, [_c('v-col', [_c('r-page-header', {
    staticClass: "searchresults--pageheader",
    attrs: {
      "label": _vm.$t('searchResults.title')
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "searchresults-main-content mt-3"
  }, [_c('v-col', {
    staticClass: "pr-2",
    attrs: {
      "sm": "6",
      "md": "6",
      "lg": "6"
    }
  }, [_c('r-text-field', {
    staticClass: "searchresults--name",
    attrs: {
      "label": _vm.$t('searchResults.fields.name.label'),
      "value": _vm.name,
      "rules": _vm.nameRules
    },
    on: {
      "change": _vm.onChangeName
    }
  })], 1), _c('v-col', {
    attrs: {
      "sm": "6",
      "md": "6",
      "lg": "6"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('resources.project')))]), _c('r-select', {
    staticClass: "projectselect mt-1",
    attrs: {
      "items": _vm.projectItems,
      "show-search": false,
      "allow-empty": false
    },
    on: {
      "change": _vm.onChangeProjectId
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "searchresults-secondary-content mt-3"
  }, [_c('v-col', {
    staticClass: "pr-2",
    attrs: {
      "sm": "6",
      "md": "6",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "automaticallyupdate--container py-3 font-weight-bold"
  }, [_c('r-switch', {
    attrs: {
      "label": _vm.$t('searchResults.fields.automaticallyUpdate.label'),
      "value": _vm.automaticallyUpdate,
      "disabled": _vm.fetchingSearch
    },
    on: {
      "change": _vm.onChangeAutomaticallyUpdate
    }
  })], 1), _vm.automaticallyUpdate ? _c('div', {
    staticClass: "automaticallyaddurls--container py-6 px-5"
  }, [_c('r-switch', {
    attrs: {
      "label": _vm.$t('searchResults.fields.automaticallyCreateUrls.label'),
      "value": _vm.automaticallyCreateUrls,
      "disabled": _vm.fetchingSearch
    },
    on: {
      "change": _vm.onChangeAutomaticallyCreateUrls
    }
  })], 1) : _vm._e()]), _c('v-col', {
    attrs: {
      "sm": "6",
      "md": "6",
      "lg": "6"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('searchResults.fields.lastListingCheck.label')) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.lastUpdateDate) + " ")])])], 1), _c('v-row', {
    staticClass: "searchresults-actions mt-2"
  }, [_c('v-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('r-error-message', {
    staticClass: "searchresults--error",
    attrs: {
      "errors": [_vm.fetchSearchError, _vm.fetchUpdateSearchError]
    }
  })], 1)], 1)], 1), _vm.showAddUrlsSuccessModal ? _c('r-modal', {
    staticClass: "addsearchresult--modal",
    attrs: {
      "title": "URLs added and queued for data collection"
    },
    scopedSlots: _vm._u([{
      key: "customActions",
      fn: function fn() {
        return [_c('r-button', {
          staticClass: "close-btn",
          attrs: {
            "outlined": true,
            "label": _vm.$t('global.actions.backToData')
          },
          on: {
            "click": _vm.backToData
          }
        })];
      },
      proxy: true
    }], null, false, 3445978720)
  }) : _vm._e(), _c('v-row', {
    staticClass: "searchresults-newresults mt-4"
  }, [_c('v-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('r-error-message', {
    staticClass: "searchnewresult--error",
    attrs: {
      "errors": [_vm.fetchNewResultsError]
    }
  })], 1), _c('v-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('r-page-header', {
    attrs: {
      "label": _vm.$t('searchResults.newUrls'),
      "type": _vm.PAGE_HEADER_TYPES.SUB_TITLE
    },
    scopedSlots: _vm._u([{
      key: "end",
      fn: function fn() {
        return [_c('r-button', {
          staticClass: "mr-2 searchresults--cancel",
          attrs: {
            "label": "Cancel",
            "outlined": true
          },
          on: {
            "click": _vm.onClickCancel
          }
        }), _c('r-button', {
          staticClass: "searchresults--save",
          attrs: {
            "label": "Save",
            "disable": _vm.fetchingSearch || _vm.fetchingUpdateSearch,
            "loading": _vm.fetchingUpdateSearch
          },
          on: {
            "click": _vm.onClickSave
          }
        })];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "mt-4 text--secondary"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "d-flex justify-end"
  }, [_c('div', [_c('r-button', {
    staticClass: "searchresults--add-data",
    attrs: {
      "label": _vm.$t('searchResults.addData'),
      "disabled": !_vm.hasSelectedItems || _vm.isUserOverQuota,
      "loading": _vm.fetchingAddSearchUrls
    },
    on: {
      "click": _vm.onClickAddData
    }
  })], 1), _c('div', [_c('v-divider', {
    staticClass: "mx-2",
    staticStyle: {
      "height": "25"
    },
    attrs: {
      "vertical": ""
    }
  })], 1), _c('div', [_c('r-search-input', {
    staticStyle: {
      "width": "300px"
    },
    attrs: {
      "value": _vm.searchTerm
    },
    on: {
      "change": _vm.onChangeSearch
    }
  })], 1)])], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('r-error-message', {
    staticClass: "searchaddnewresult--error",
    attrs: {
      "errors": [_vm.fetchAddSearchUrlsError, _vm.userOverQuotaMessage]
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('new-result-filter-chips', {
    attrs: {
      "filters": _vm.newResultFilters,
      "loading": _vm.fetchingNewResults
    },
    on: {
      "apply": _vm.onClickApplyNewResultsFilters
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('r-table', {
    class: {
      'no-results': !_vm.newResults.length
    },
    attrs: {
      "columns": _vm.tableColumns,
      "rows": _vm.newResults,
      "page": _vm.newResultPage,
      "page-size": _vm.newResultPageSize,
      "total-rows": _vm.totalNewResults,
      "loading": _vm.fetchingNewResults
    },
    on: {
      "change:page": _vm.onChangeNewResultPage,
      "change:page-size": _vm.onChangeNewResultsPageSize,
      "change:page-size-all": _vm.onChangeNewResultsPageSizeAll,
      "change:batch-select": _vm.onChangeBatchNewResultSelection,
      "change:batch-select-all": _vm.onSelectAllNewResults,
      "sort:asc": function sortAsc(_ref) {
        var value = _ref.value;
        return _vm.onChangeNewResultsSort({
          sort: value,
          sortDir: 'asc'
        });
      },
      "sort:desc": function sortDesc(_ref2) {
        var value = _ref2.value;
        return _vm.onChangeNewResultsSort({
          sort: value,
          sortDir: 'desc'
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "text-truncate",
          attrs: {
            "title": item.name
          }
        }, [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }, {
      key: "item.url",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('a', {
          attrs: {
            "href": item.url,
            "title": item.url,
            "target": "_blank"
          },
          domProps: {
            "innerHTML": _vm._s(_vm.abbrevUrl(item.url))
          }
        })];
      }
    }, {
      key: "item.numReviews",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', [_vm._v(_vm._s(_vm.formatNumReview(item.numReviews)))])];
      }
    }, {
      key: "item.linkName",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "text-truncate",
          attrs: {
            "title": item.linkName
          }
        }, [_vm._v(" " + _vm._s(item.linkName) + " ")])];
      }
    }])
  })], 1)], 1), _vm.totalAddedResults ? _c('v-row', {
    staticClass: "searchresults-addedresults mt-4"
  }, [_c('v-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('r-error-message', {
    attrs: {
      "errors": [_vm.fetchAddedResultsError]
    }
  })], 1), _c('v-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('r-page-header', {
    attrs: {
      "label": _vm.$t('searchResults.addedUrls'),
      "type": _vm.PAGE_HEADER_TYPES.SUB_TITLE
    }
  })], 1), _c('v-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('r-table', {
    attrs: {
      "columns": _vm.tableColumns,
      "rows": _vm.addedResults,
      "page": _vm.addedResultPage,
      "page-size": _vm.addedResultPageSize,
      "total-rows": _vm.totalAddedResults,
      "loading": _vm.fetchingAddedResults,
      "is-selectable": false
    },
    on: {
      "change:page": _vm.onChangeAddedResultPage,
      "change:page-size": _vm.onChangeAddedResultsPageSize,
      "change:page-size-all": _vm.onChangeAddedResultsPageSizeAll,
      "sort:asc": function sortAsc(_ref7) {
        var value = _ref7.value;
        return _vm.onChangeAddedResultsSort({
          sort: value,
          sortDir: 'asc'
        });
      },
      "sort:desc": function sortDesc(_ref8) {
        var value = _ref8.value;
        return _vm.onChangeAddedResultsSort({
          sort: value,
          sortDir: 'desc'
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('div', {
          staticClass: "text-truncate",
          attrs: {
            "title": item.name
          }
        }, [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }, {
      key: "item.url",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('a', {
          attrs: {
            "href": item.url,
            "title": item.url,
            "target": "_blank"
          },
          domProps: {
            "innerHTML": _vm._s(_vm.abbrevUrl(item.url))
          }
        })];
      }
    }, {
      key: "item.numReviews",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('span', [_vm._v(_vm._s(_vm.formatNumReview(item.numReviews)))])];
      }
    }], null, false, 1319529462)
  })], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }